import styled from 'styled-components';

export const StyledThing = styled.div`
@keyframes pulse {
  50% { opacity: .5; }
}

.skeleton-div {
  // background-color: #f9f9f9;
  background-color: #e9e9e9;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin-animation {
  animation: spin 2s linear infinite;
}


.sak__side_extention_wrapper .side-extension-close-bnt {
  visibility: hidden;
}

.sak__side_extention_wrapper .side-extension-close-bnt button {
  font-size: 1.2rem;
  gap: .375rem;
  color: var(--svg);
}

.sak__side_extention_wrapper .side-extension-close-bnt button:hover {
  background-color: var(--secondary);
  color: white;
}

.chat-sidebar.chat-sidebar-visible & .sak__side_extention_wrapper .side-extension-close-bnt {
  visibility: visible;
}

@media (min-width: 1200px) {
  .sak__side_extention_wrapper .side-extension-close-bnt {
    visibility: hidden !important;
  }
}

.sak__side_extention_wrapper .form-control {
  padding: .375rem .75rem;
}


.sak__side_extention_wrapper * {
  outline: none;
}

// .sak__side_extention_wrapper *,
// .sak__side_extention_wrapper *:before,
// .sak__side_extention_wrapper *:after {
//   padding: 0;
//   margin: 0;
//   outline: none;
// }
.sak__side_extention_wrapper *,
.sak__side_extention_wrapper *:before {
  padding: 0;
  margin: 0;
  outline: none;
}

.sak__side_extention_wrapper form {
  display: block;
}

.sak__side_extention_wrapper {
  font-family: 'Poppins', sans-serif;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  color: #8da2b5;
  z-index: 1000;
  font-size: 12px;
}

.sak__side_extention_wrapper {
  width: 100%;
  height: 100%;
  padding: 10px;
  background: var(--light);
}

.sak__side_extention_wrapper::-webkit-scrollbar {
  display: none;
}

.sak__side_extention_wrapper main::-webkit-scrollbar {
  display: none;
}

.sak__side_extention_wrapper .page-content::-webkit-scrollbar {
  display: none;
}

.sak__side_extention_wrapper .page-content {
  overflow-y: scroll;
  height: calc(100vh - 87px) !important;
}

.sak__side_extention_wrapper .page-content-total {
  height: 100vh !important;
}

.sak__side_extention_wrapper .page-content-scroll {
  overflow-y: scroll;
  max-height: calc(100vh - 206px);
  position: relative;
}

.sak__side_extention_wrapper .page-content-scroll::before {
  position: fixed;
  content: '';
  bottom: 0;
  right: 0;
  left: 40px;
  box-shadow: 0px 0px 25px 30px #f5f6fa;
}

.sak__side_extention_wrapper .page-content-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.sak__side_extention_wrapper .page-content-scroll::-webkit-scrollbar {
  width: 5px;
}

.sak__side_extention_wrapper .page-content-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #ccc;
}

.sak__side_extention_wrapper .text-azul {
  color: #061058 !important;
}

.sak__side_extention_wrapper .faq--title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  color: black;
  font-weight: 500;
  background: transparent;
  padding: 5px 15px;

  cursor: pointer;
  transition: all 0.3s;
}

.sak__side_extention_wrapper .faq--title:hover,
.sak__side_extention_wrapper .faq--title.active {
  background-color: #f5f7fa;
}

.sak__side_extention_wrapper .faq--title+.faq--title {
  margin-top: 5px;
}

.sak__side_extention_wrapper .faq--title img {
  width: 22px;
}

.sak__side_extention_wrapper .faq--title a {
  display: block;
}

.sak__side_extention_wrapper .lista-artigos {
  border: 1px solid rgba(205, 208, 227, 0.3) !important;
  background-color: #fff;
  margin-bottom: 15px;
  border-radius: 3px;
  padding: 8px 0;
}

.sak__side_extention_wrapper .lista-artigos h5 {
  padding: 3px 15px 13px;
  font-size: 1rem;
  font-weight: 500;
  color: black;
  border-bottom: 1px solid rgba(205, 208, 227, 0.3);
}

.sak__side_extention_wrapper .page-content .content-single {
  display: flex;
  flex-direction: column;
  padding: 22px;
  font-size: 1rem;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.sak__side_extention_wrapper .page-content .content-single h1 {
  margin: 0;
  font-size: 18px !important;
  // color: #828bab;
  color: var(--basic-invert);
  font-weight: 500;
  text-transform: none;
  margin-bottom: 10px;
}

// .sak__side_extention_wrapper .page-content .content-single p {
//   font-size: 16px;
// }


.sak__side_extention_wrapper .page-content .content-single p img {
  width: 100% !important;
}

/* RESETS */

.sak__side_extention_wrapper .form-control:focus {
  box-shadow: none;
}

.sak__side_extention_wrapper .fa-clone {
  cursor: pointer;
}

/*COLORS*/

.sak__side_extention_wrapper .color-success {
  color: #94be47;
}

/*HELPERS*/

.sak__side_extention_wrapper .p-t-0 {
  padding-top: 0 !important;
}

.sak__side_extention_wrapper .font-size-md {
  font-size: 1em;
}

.sak__side_extention_wrapper .section-title {
  color: #4b63af;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 1.4;
  font-size: 16px;
  border-bottom: 1px dashed #e9e9e9;
  padding-bottom: 10px;
}

.sak__side_extention_wrapper .section-title-order {
  margin-top: 1rem;
}

.sak__side_extention_wrapper .overlay-updated {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;
  background: rgba(255, 255, 255, 0.8);
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sak__side_extention_wrapper .overlay-updated.active {
  display: flex;
  transition: all 0.5s ease;
}

.sak__side_extention_wrapper .overlay-updated .overlay-title {
  font-size: 1.3rem;
  margin-top: 10px;
}

/*HEADER*/

.sak__side_extention_wrapper .main-header {
  // background: #161931;
  background: transparent;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#6877e6', endColorstr='#3141b8', GradientType=1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.sak__side_extention_wrapper .main-header .main-header-link {
  color: rgba(255, 255, 255, 0.95);
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-self: normal;
  // padding: 15px 10px;
  padding: .75rem .5rem;
  text-decoration: none;
  border: none;
}

.sak__side_extention_wrapper .main-header .main-header-link i {
  // color: inherit;
  color: var(--svg);
}

.sak__side_extention_wrapper .main-header .main-header-link:hover {
  color: rgba(49, 65, 184, 1);
  // background: #fff;
  border: none;
}

.sak__side_extention_wrapper .main-header .main-header-link.active {
  position: relative;
}

.sak__side_extention_wrapper .main-header .main-header-link.active:after {
  position: absolute;
  content: '';

  aspect-ratio: 1;
  width: 100%;
  
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;

  top: 50%;
  transform: translateY(-50%);

  z-index: -1;

  background: var(--secondary);
  border-radius: .375rem;
}

.sak__side_extention_wrapper .main-header .profile-content {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sak__side_extention_wrapper .main-header .profile-name {
  position: relative;
}

.sak__side_extention_wrapper .main-header .profile-content:hover .profile-name {
  background-color: #6c00ff;
}

.sak__side_extention_wrapper .main-header .profile-name .name {
  color: #fff;
  text-align: center;
}

.sak__side_extention_wrapper .main-header .profile-name .status {
  width: 10px;
  height: 10px;
  position: absolute;
  background-color: #fff;
  padding: 2px;
  border-radius: 50%;
  right: 2px;
  bottom: 0px;
}

.sak__side_extention_wrapper .main-header .profile-name .status:after {
  content: '';
  width: 9px;
  height: 9px;
  background-color: #e8ff03;
  position: absolute;
  border-radius: 50%;
  right: 0px;
  top: 0px;
}

.sak__side_extention_wrapper .main-header .profile-content .caret {
  margin: 0 8px;
  color: #fff;
}

.sak__side_extention_wrapper .main-header .dropdown-menu-sak {
  border-radius: 6px;
  box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), -1px 0 15px rgba(0, 0, 0, 0.07);
  transform: translate3d(-125px, 0px, 0px) !important;
}

/*FORM SEARCH*/

.sak__side_extention_wrapper .main-search {
  padding: 10px;
  background-color: #fff;
}

.sak__side_extention_wrapper .main-search .form-search {
  background: #f1f4f9;
  border: 0;
  transition: background-color ease 0.2s;
  border-radius: 20px;
  position: relative;
}

.sak__side_extention_wrapper .main-search .form-search button {
  flex: 0;
  right: 15px;
  color: #777;
  font-size: 1.2em;
  position: absolute;
  top: 10px;
  border: 0;
}

.sak__side_extention_wrapper .main-search .input-search {
  color: #fff;
  flex: 1;
  border: 0;
  background: none;
  flex: 10;
  border-radius: 20px;
  box-shadow: none;
}

.sak__side_extention_wrapper .main-search .input-search::after {
  content: '\f002';
}

.sak__side_extention_wrapper .main-search .input-search::placeholder {
  color: #777;
  font-size: 0.8em;
}

.sak__side_extention_wrapper .main-search .btn-search {
  margin-left: 10px;
  background: transparent;
  padding: 0;
}

.sak__side_extention_wrapper .sak--form-group label {
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #424242;
  margin-bottom: 4px;
}

.sak__side_extention_wrapper .sak--form-group input {
  width: 100%;
  margin-bottom: 24px;
  border: 1px solid #d3d9eb;
  background: transparent;
  border-radius: 4px;
  padding: 5px 10px;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #424242;
}

.sak__side_extention_wrapper .sak--form-group textarea {
  margin-bottom: 12px;
  border: 1px solid #d3d9eb;
  background: transparent;
  border-radius: 4px;
  padding: 5px 10px;
  width: 100%;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #424242;
  resize: none;
  min-height: 130px;
  padding: 10px;
}

.sak__side_extention_wrapper .pointer {
  cursor: pointer;
}

.sak__side_extention_wrapper .sak--form-group div {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 14px;
  border: 1px solid #d3d9eb;
  padding: 9px 10px;
  border-radius: 4px;
}

.sak__side_extention_wrapper .sak--form-group div input {
  margin-left: 8px;
  margin-bottom: 0;
  padding: 0;
  flex: 1;
  border: none;
  margin-right: 8px;
}

.sak__side_extention_wrapper .sak--form-group div input::placeholder {
  color: #828ba7a8;
}

.sak__side_extention_wrapper .sak--form-group div i {
  color: #b3bcdc;
  font-size: 1.1rem;
}

.sak__side_extention_wrapper .sak--form-group button {
  padding: 10px 24px;
  width: 100%;
  background: #fddb78;
  border-radius: 4px;
  border: none;
  font-weight: 500;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #171b37;

  transition: 2s;
}

.sak__side_extention_wrapper .sak--form-group button:hover {
  background: #daba5d;
}

.sak__side_extention_wrapper .nav-tabs {
  border-bottom: none !important;
}

.sak__side_extention_wrapper i {
  color: #828bab;
}

.sak__side_extention_wrapper .sak--ext-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #374957;

  display: flex;
  align-items: center;
}

.sak__side_extention_wrapper .sak--ext-title.link {
  color: #3e62ff;
  text-decoration: none;
}

.sak__side_extention_wrapper .sak--ext-title.link:hover {
  color: #6e88fa;
}

.sak__side_extention_wrapper .sak--ext-title i {
  font-size: 1.2rem;
  color: #374957;
  margin-top: 1px;
}

.sak__side_extention_wrapper .sak--ext-sub-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #3e62ff;

  display: flex;
  align-items: center;
}

.sak__side_extention_wrapper .sak--perfil-top-divisor {
  border-top: 1px solid rgba(179, 188, 220, 0.25);
}

.sak__side_extention_wrapper .sak--perfil-info ul {
  // border-top: 1px solid rgba(179, 188, 220, 0.25);
  padding-top: 10px;
  margin-top: 10px;
  list-style: none;
}

.sak__side_extention_wrapper .sak--perfil-info ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: #828bab;
}

.sak__side_extention_wrapper .sak--perfil-info ul li+li {
  margin-top: 10px;
}

.sak__side_extention_wrapper .sak--perfil-info ul li strong {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #424242;
  min-width: 100px;
}

.sak__side_extention_wrapper .sak--perfil-info ul li span {
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  // color: #828bab;
  color: #828bab !important;
}

.sak__side_extention_wrapper .sak--perfil-info ul li span i {
  margin-left: 4px;
  color: #828bab;
  cursor: pointer;
  margin-top: 3px;
  font-size: 0.8rem;
}

.sak__side_extention_wrapper .nav-tabs {
  background: transparent;
  border-radius: 0.375rem;
}

.sak__side_extention_wrapper .SFnav .nav-item {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-right: 5px;
  margin-bottom: 5px;
}

.sak__side_extention_wrapper .SFnav .nav-link {
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  color: #7d98be;
  padding: 4px 10px;
  border: none;
  background: var(--secondary);
}

.sak__side_extention_wrapper .SFnav .nav-link:hover {
  border: none;
  color: #49c18fc0;
  background: rgba(73, 193, 143, 0.1);
}

.sak__side_extention_wrapper .SFnav .nav-item.show .nav-link,
.sak__side_extention_wrapper .SFnav .nav-link.active {
  color: #49c18f;
  background: rgba(73, 193, 143, 0.15);
  border-radius: 3px;

  border: none;
}

.sak__side_extention_wrapper .tab-info-items {
  margin-top: 10px;
}

.sak__side_extention_wrapper .tab-content {
  padding: 0 10px;
}

.sak__side_extention_wrapper .customers-content .customers-item {
  margin: 10px 0;
}

.sak__side_extention_wrapper .customers-content .customers-item:last-child {
  border-bottom: 0;
}

.sak__side_extention_wrapper .customers-content .nav-customers-info li {
  font-size: 12px;
  font-weight: 300;
}

.sak__side_extention_wrapper .customers-content .customers-media .title-product {
  font-size: 0.8em;
  font-weight: 300;
}

.sak__side_extention_wrapper .customers-content .customers-media .nav-variant li {
  font-size: 0.8em;
  font-weight: 300;
}

.sak__side_extention_wrapper .customers-content .customers-media img:hover {
  cursor: all-scroll;
}

/*CARD RASTREIO*/

.sak__side_extention_wrapper .rastreio-content {
  display: flex;
  flex-direction: column;
}

.sak__side_extention_wrapper .rastreio-content .rastreio-status {
  margin-top: 10px;
}

.sak__side_extention_wrapper .rastreio-content .rastreio-status-content {
  padding: 5px;
  font-size: 0.8em;
  border-radius: 6px;
  display: inline-block;
}

.sak__side_extention_wrapper .rastreio-content .rastreio-info small {
  font-size: 0.6rem;
  margin: 0;
  margin-bottom: 10px;
}

.sak__side_extention_wrapper .rastreio-content .rastreio-info:last-child small {
  margin-bottom: 0;
}

/*BADGES*/

.sak__side_extention_wrapper .badge {
  font-weight: 400 !important;
  // padding: 0.5625rem 0.75rem;
  padding: .375rem .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sak__side_extention_wrapper .badge-soft-primary {
  color: #fff !important;
  background-color: #6c00ff !important;
}

.sak__side_extention_wrapper .badge-soft-success {
  color: #fff !important;
  background-color: #59d457 !important;
}

.sak__side_extention_wrapper .badge-soft-warning {
  color: #fff !important;
  background-color: #ffc107 !important;
}

.sak__side_extention_wrapper .badge-soft-danger {
  color: #fff !important;
  background-color: #ff5a80 !important;
}

.sak__side_extention_wrapper .badge-soft-info {
  color: #fff !important;
  background-color: #71c4ff !important;
}

.sak__side_extention_wrapper .badge-soft-gray {
  color: #fff !important;
  background-color: #929292 !important;
}

.sak__side_extention_wrapper .badge-sak-light {
  background-color: #fff;
  color: #161f31;
  border-radius: 10px;
}

.sak__side_extention_wrapper .badge-pill {
  // padding-right: 0.6875rem;
  // padding-left: 0.6875rem;
  border-radius: 10rem;
  
  padding-right: 0.6rem;
  padding-left: 0.6rem;
}

/* BUTTONS */

.sak__side_extention_wrapper .btn{
  padding: .375rem .75rem;
}

.sak__side_extention_wrapper .btn-sak-success {
  color: #fff;
  background-color: #e8ff03;
  border-color: #e8ff03;
}

.sak__side_extention_wrapper .btn-sak-success:hover,
.sak__side_extention_wrapper .btn-sak-success:focus {
  background-color: #82b294;
  border-color: #82b294;
}

.sak__side_extention_wrapper .btn-sak-primary {
  color: #fff !important;
  background-color: #6c00ff;
  border-color: #6c00ff;
}

.sak__side_extention_wrapper .btn-sak-primary:hover {
  background-color: #8830ff;
  border-color: #8830ff;
}

.sak__side_extention_wrapper .btn-sak-info {
  color: #fff !important;
  background-color: #00c1f8;
  border-color: #00c1f8;
}

.sak__side_extention_wrapper .btn-sak-info:hover {
  background-color: #00c6ff;
  border-color: #00c6ff;
}

/*COLORS*/

.sak__side_extention_wrapper .color-danger {
  color: #ff17aa;
}

.sak__side_extention_wrapper .color-dark {
  color: #6c757d;
}

.sak__side_extention_wrapper .color-secondary {
  color: #dadee6;
}

.sak__side_extention_wrapper .dropdown-item {
  white-space: pre-wrap !important;
  padding: .25rem 1.5rem;
}

.sak__side_extention_wrapper .dropdown-item.active,
.sak__side_extention_wrapper .dropdown-item:active {
  background-color: var(--secondary);
  color: inherit;
}

/*TITLE HEADER*/

.sak__side_extention_wrapper .title-header {
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
}

.sak__side_extention_wrapper .title-header2 {
  border-bottom: 1px solid rgb(231, 231, 231);
  margin: 0 20px 10px;
}

.sak__side_extention_wrapper .title-header .text {
  font-size: 0.875em;
}

/*FORM TEXT*/

.sak__side_extention_wrapper .form-text-item {
  margin: 20px 0;
  background-color: #fff;
  border-color: #fff;
  margin-bottom: 20px;
  border: 0;
  box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.07),
    0 2px 9px 0 rgba(18, 106, 211, 0.06);
  padding: 10px;
}

.sak__side_extention_wrapper .form-text-item input[type='text'],
.sak__side_extention_wrapper .form-text-item textArea {
  margin-bottom: 20px;
  box-shadow: none;
}

.sak__side_extention_wrapper .form-text-item .btn-trash {
  transition: all 0.5s ease;
}

.sak__side_extention_wrapper .form-text-item .btn-trash:hover {
  transform: scale(1.2);
  color: #6c00ff;
}

/* FAQ */

.sak__side_extention_wrapper .dropdown-menu-filtro {
  background-color: #1d2941;
}

.sak__side_extention_wrapper .dropdown-menu-filtro>a {
  color: #fff;
}

.sak__side_extention_wrapper .dropdown-menu-filtro>a:hover {
  background-color: #6c00ff;
  color: #fff;
}

.sak__side_extention_wrapper .faq-item {
  margin-bottom: 20px;
  border-radius: 6px;
  background-color: #fff;
  border-color: #fff;
  margin-bottom: 20px;
  border: 0;
  box-shadow: 0px 6px 10px rgba(29, 41, 65, 0.1);
  padding: 10px;
}

.sak__side_extention_wrapper .faq-item ul li {
  margin: 5px 0;
}

/* CARD CONTENT */

.sak__side_extention_wrapper .top-line {
  margin: 0;
  height: 4px;
}

.sak__side_extention_wrapper .top-line-success {
  background-color: #7fcc7f;
}

.sak__side_extention_wrapper .extensao--header {
  position: relative;
  padding: 12px;
  // background: #00b85b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--basic-inverse);
}

.sak__side_extention_wrapper .extensao--header strong {
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  // color: white !important;
  text-transform: none;
  margin-bottom: 0;
}

.sak__side_extention_wrapper .extensao--header i {
  color: var(--basic-inverse);
}

.sak__side_extention_wrapper .extensao--header button:focus {
  outline: 0;
}

.sak__side_extention_wrapper .extensao--header button,
.sak__side_extention_wrapper .extensao--header a[type=button] {
  border: none;
  background: transparent;
  padding: 10px 14px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: start;
  transition: all 0.2s;
  outline: 0;
  // color: var(--basic-inverse);
}

.sak__side_extention_wrapper .extensao--header button:hover,
.sak__side_extention_wrapper .extensao--header a[type=button]:hover {
  // background: #139d57;
  background: var(--secondary);
  outline: 0;
}

.sak__side_extention_wrapper .main-menu {
  position: absolute;
  left: 0;
  height: 100%;
  max-width: 60px;
  background-color: #fff;
  border-right: 1px solid #eee;
}

.sak__side_extention_wrapper .main-menu>ul>li {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eee;
  align-items: center;
  justify-content: center;
}

.sak__side_extention_wrapper .main-menu>ul>li:last-child {
  border: 0;
}

.sak__side_extention_wrapper .main-menu>ul>li>a {
  color: #777;
  padding: 10px;
  transition: all 0.5s ease;
}

.sak__side_extention_wrapper .main-menu>ul>li>a:hover {
  color: #333;
  transform: scale(1.3);
}

.sak__side_extention_wrapper .saknavapps {
  padding: 0 10px;
}

.sak__side_extention_wrapper .saknavapps .nav-item .nav-link i {
  color: unset;
}

.sak__side_extention_wrapper .saknavapps .nav-item .nav-link i::after {
  content: '\\00a0';
}

.sak__side_extention_wrapper .nav-card-items.nav-tabs .nav-link.active {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 3px solid #e8ff03;
}

.sak__side_extention_wrapper .card-content {
  width: calc(100% - 60px);
  float: right;
  margin-left: 60px;
}

.sak__side_extention_wrapper .card-content p {
  font-size: 0.8em;
}

/* MAIN FOOTER*/

.sak__side_extention_wrapper .main-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #eee;
  padding: 10px 0;
}

/**************** PAGES ****************/

/* INFO */

.sak__side_extention_wrapper .input-md {
  border: 0;
  border-bottom: 1px solid #e9e9e9;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.sak__side_extention_wrapper [integration='true'] svg {
  fill: #4b63af;
}

.sak__side_extention_wrapper .nav-link:not([integration='true']) svg {
  fill: #8da2b5;
}

.sak__side_extention_wrapper .input-md:focus {
  box-shadow: none;
  border-color: #777;
}

.sak__side_extention_wrapper .form-group>label:nth-child(1) {
  display: block;
}

.sak__side_extention_wrapper .tag {
  border: 1px solid;
  border-radius: 4px;
  padding: 3px;
  font-size: 0.7rem;
  margin-bottom: 5px !important;
  display: inline-block;
}

.sak__side_extention_wrapper .tag:hover {
  cursor: pointer;
}

.sak__side_extention_wrapper .tag-danger {
  color: #ea317a;
  border-color: #ea317a;
}

.sak__side_extention_wrapper .tag-danger:hover {
  background: #ea317a;
  color: #fff;
}

.sak__side_extention_wrapper .tag-danger.active {
  background: #ea317a;
  color: #fff;
}

.sak__side_extention_wrapper .box-user {
  display: flex;
  align-items: center;
}

.sak__side_extention_wrapper .box-user .user-info {
  margin-left: 10px;
}

.sak__side_extention_wrapper .box-user .user-name {
  font-size: 1rem;
  font-weight: bold;
}

.sak__side_extention_wrapper .box-user .user-tel {
  font-size: 0.8rem;
  color: #777;
}

.sak__side_extention_wrapper .info-nav-items .nav-tabs {
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sak__side_extention_wrapper .info-nav-items .nav-tabs .nav-link {
  color: #7d98be;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 7px;
  margin-right: 10px;
  border: none;
  border-bottom: 2px solid transparent;
}

.sak__side_extention_wrapper .info-nav-items .nav-tabs .nav-link:hover {
  color: #378564;
  border: none;
  border-bottom: 3px solid #49c18f;
}

.sak__side_extention_wrapper .info-nav-items .nav-tabs .nav-link.active {
  border: 0;
  border-bottom: 3px solid #49c18f;
  color: #49c18f;
}

.sak__side_extention_wrapper .info-nav-items .nav-tabs .nav-link.active svg {
  fill: #49c18f !important;
}

.sak__side_extention_wrapper .form-info .form-group>label {
  margin-bottom: 0;
  color: #8da2b5;
  font-size: 0.8rem;
}

/* css do audio whatsapp speed */

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}

/* LOGIN */

.sak__side_extention_wrapper #dv_login {
  padding-left: 15px;
  padding-right: 15px;
}

/* SLIDER AUDIO */

.layer {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 49px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: none;
  transition: all 0.5s ease;
}

.sak__side_extention_wrapper .main-content-audio {
  // box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.07),
  //   0 2px 9px 0 rgba(18, 106, 211, 0.06);

  // border: 1px solid #e9e9e9;
  border: 1px solid rgba(205,208,227,0.3) !important;
  border-radius: 3px;

  // position: absolute;
  margin-top: 10px;

  right: 0;
  left: 0;
  z-index: 200;
}

.sak__side_extention_wrapper .main-content-audio .main-text {
  // background-color: #00cd5f;
  // color: #fff;
  color: var(--basic-inverse);
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sak__side_extention_wrapper .main-content-audio .main-text .title {
  font-size: 1rem;
}

.sak__side_extention_wrapper .main-content-audio .main-text p {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}

.sak__side_extention_wrapper .main-content-audio .main-text p span {
  font-size: 5rem;
  font-weight: bold;
}

.sak__side_extention_wrapper .main-content-audio .slidecontainer {
  // background: #fff;
  background: var(--basic);
  padding: 20px 10px;
  display: flex;
}

.sak__side_extention_wrapper .main-content-audio .slidecontainer i {
  margin-right: 10px;
  color: var(--basic-inverse);
}

.sak__side_extention_wrapper .main-content-audio .slider {
  // background: var(--basic-inverse);
}

/*
.folder-list{
    display: flex;
    flex-direction: column;
}

.folder-list li{
    display: inline-block;
    border-top: 1px solid #e9e9e9;
    padding: 10px 5px !important;
}

.folder-list li:hover{
    background: #f7f7f7;
    cursor: pointer;
}

.folder-list li .valign-wrapper {
    display: flex;
    align-items: center;
}

.folder-list li .icon-folder {
    padding: 10px !important;
    background-color: #0c9;
    margin-right: 15px !important;
    border-radius: 50%;
    color: #fff;
    font-size: 1.2rem !important;
}

.folder-list li .title{
    font-size: 0.875rem !important;
}

.folder-list li .icon-info{
    margin-left: auto !important;
    font-size: 1.5rem !important;
    color: #ccc;
}

.folder-list li .title span{
    color: rgba(0, 0, 0, 0.54);
}

.folder-list li:first-child{
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
}

*/

.sak__side_extention_wrapper .folder-list {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  flex-wrap: wrap;
}

.sak__side_extention_wrapper .folder-list .folder-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-right: 5px;
  margin-bottom: 15px;
  width: 86px;
  padding: 5px;
  border-radius: 4px;
}

.sak__side_extention_wrapper .folder-list .folder-item a {
  color: #212529;
}

.sak__side_extention_wrapper .folder-list .folder-item:hover {
  background: #f1f4f9;
  cursor: pointer;
}

.sak__side_extention_wrapper .folder-list .folder-item:nth-of-type(3n + 0) {
  margin-right: 0;
}

.sak__side_extention_wrapper .folder-list .folder-item .title {
  font-size: 0.5rem;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  margin-top: 5px;
}

.sak__side_extention_wrapper .icon-folder {
  display: inline-block;
  background-color: transparent;
  overflow: hidden;
  position: relative;
}

.sak__side_extention_wrapper .icon-folder:before {
  content: '';
  float: left;
  background-color: #7ba1ad;
  width: 1.5em;
  height: 0.55em;
  margin-left: 0.07em;
  margin-bottom: -0.07em;
  border-top-left-radius: 0.1em;
  border-top-right-radius: 0.1em;
  box-shadow: 1.25em 0.25em 0 0em #7ba1ad;
}

.sak__side_extention_wrapper .icon-folder:after {
  box-shadow: 0 -0.12em 0 0 #ffffff;
  content: '';
  float: left;
  clear: left;
  background-color: #a0d4e4;
  width: 3em;
  height: 2.15em;
  border-radius: 0.1em;
}

.sak__side_extention_wrapper .icon-folder-back:before {
  background-color: #6e8288;
  box-shadow: 1.25em 0.25em 0 0em #6e8288;
}

.sak__side_extention_wrapper .icon-folder-back:after {
  background-color: #70858c;
  box-shadow: 0 -0.12em 0 0 #ffffff;
  height: 1.8em;
}

.sak__side_extention_wrapper .icon-folder-back .folder-back {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -8px;
  margin-top: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

/* PRODUCT IMAGE */

.sak__side_extention_wrapper .ul-product {
  margin-bottom: 0;
  padding-top: .5rem;
  list-style: none;
}

.sak__side_extention_wrapper .ul-product .li-product {
  margin-bottom: .25rem;
}

.sak__side_extention_wrapper .li-product-info {
  display: flex;
}

.sak__side_extention_wrapper .product-image img {
  max-width: 100%;
  width: 50px;
}

.sak__side_extention_wrapper .product-info {
  font-size: 0.875rem;
}

/* TABLE ORDER */
.sak__side_extention_wrapper .table-order td,
.table-order th {
  padding: 5px;
  border-color: #c1c7d0;
  font-size: 0.875rem;
}

.rastreio-number button {
  border: none;
  background: transparent;
}

.rastreio-number form {
  display: contents !important;
}

.sak__side_extention_wrapper .sIconWhats {
  font-size: 30px;
  cursor: pointer;
  text-decoration: none;
}

.sak__side_extention_wrapper .sIconWhats.small {
  color: #828bab;
  font-size: 25px;
  text-decoration: none;
  transition: 0.2s;
}

.sak__side_extention_wrapper .sIconWhats.small:hover {
  color: #78809e;
}

.sak__side_extention_wrapper .sIconWhats a {
  text-decoration: none;
  margin: 5px 0 8px;
}

.sak__side_extention_wrapper .sIconWhats a:hover {
  color: #59d457 !important;
}

#div_msgs {
  position: absolute;
  background: #161931;
  top: 49px;
  color: #fff;
  font-size: 12px;
  padding: 10px;
  display: none;
}

.iconUser {
  background: #ff4081;
  padding: 6px 7px;
  color: #fff;
  padding: 5px !important;
}

.sak__side_extention_wrapper .dropdown-menu {
  max-width: 240px;
  margin-left: -80px;
}

.login-card {
  padding-top: 20px;
}

.login-card img {
  margin: 0 auto;
  margin-bottom: 10px;
}

.login-card header {
  text-align: center;
}

.login-card .account-info {
  text-align: center;
  display: block;
}

.login-card__content .main-card-section>.ui-heading {
  font-size: 18px;
  text-align: center;
}

.login-card .login-item a {
  padding: 6px;
}

.login-card .login-info h4 {
  font-size: 10px;
}

.login-2 .form-section .form-box input {
  padding: 11px 20px 11px 50px !important;
}

.sak__side_extention_wrapper .fa-copy {
  cursor: pointer;
}

.sak__side_extention_wrapper .foto-prod {
  // width: 40px;
  // margin-left: 11px;
  width: 2rem;
}

.sak__side_extention_wrapper .detalhe-prod {
  width: 200px;
  float: left;
  margin-left: 10px;
  font-size: 12px;
}

.sak__side_extention_wrapper .imgProd {
  // margin-left: 3px;
  border-radius: 5px;
  padding: 0;
  margin: 0;
  max-width: 100%;
  width: 2rem;
}

.sak__side_extention_wrapper .SFnav {
  font-size: 12px;
}

// .sak__side_extention_wrapper #simMsg {
//   background-image: url(/image/whats_back.png);
//   border-top: 2px solid #009688;
//   display: none;
//   position: fixed;
//   bottom: 0;
//   right: 0;
//   width: 100%;
//   z-index: 100;
//   pointer-events:none;
// }

.sak__side_extention_wrapper .simBack {
  padding: 20px;
  background-color: #e5ddd5;
}

.sak__side_extention_wrapper .boxWhats {
  margin-bottom: 10px;
  float: left;
  background-color: #ffffff;
  border-radius: 7.5px;
  border-top-left-radius: 0px;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
  max-width: 90%;
  font-family: 'Open Sans', sans-serif;
  font-size: 13.6px;
  line-height: 19px;
  color: #262626;
  position: relative;
}

.sak__side_extention_wrapper .seta {
  left: -12px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACQUExURUxpccPDw9ra2m9vbwAAAAAAADExMf///wAAABoaGk9PT7q6uqurqwsLCycnJz4+PtDQ0JycnIyMjPf3915eXvz8/E9PT/39/RMTE4CAgAAAAJqamv////////r6+u/v7yUlJeXl5f///5ycnOXl5XNzc/Hx8f///xUVFf///+zs7P///+bm5gAAAM7Ozv///2fVensAAAAvdFJOUwCow1cBCCnqAhNAnY0WIDW2f2/hSeo99g1lBYT87vDXG8/6d8oL4sgM5szrkgl660OiZwAAAHRJREFUKM/ty7cSggAABNFVUQFzwizmjPz/39k4YuFWtm55bw7eHR6ny63+alnswT3/rIDzUSC7CrAziPYCJCsB+gbVkgDtVIDh+DsE9OTBpCtAbSBAZSEQNgWIygJ0RgJMDWYNAdYbAeKtAHODlkHIv997AkLqIVOXVU84AAAAAElFTkSuQmCC);
  content: '';
  position: absolute;
  top: 0px;
  width: 12px;
  height: 19px;
}

.sak__side_extention_wrapper .boxWhatsTxt {
  padding: 6px 7px 8px 9px;
  min-width: 110px;
  box-sizing: border-box;
  white-space: pre-line;
}

.texto iframe {
  width: 100%;
}

.sak__side_extention_wrapper .whatsappOn,
.sak__side_extention_wrapper .whatsappOff,
.sak__side_extention_wrapper .whatsappWhait {
  display: inline-block;
  border: 2px solid transparent;
  margin: 5px;
  position: relative;
}

.sak__side_extention_wrapper a.whatsappOff i:hover,
.sak__side_extention_wrapper .whatsappOff:hover {
  color: #5ecdcb;
}

.sak__side_extention_wrapper a.whatsappOn i,
.sak__side_extention_wrapper .whatsappOn {
  color: #5ecdcb;
}

.sak__side_extention_wrapper a.whatsappOn i:hover,
.sak__side_extention_wrapper .whatsappOn:hover {
  color: #5ecdcb;
}

.sak__side_extention_wrapper a.whatsappErr i,
.sak__side_extention_wrapper .whatsappErr {
  color: #ff5c75;
}

.sak__side_extention_wrapper a.whatsappWhait i,
.sak__side_extention_wrapper .whatsappWhait {
  color: #ffd707;
}

.sak__side_extention_wrapper .whatsappOn .badge {
  position: absolute;
  border-radius: 50%;
  display: block;
  color: #fff;
  text-align: center;
  width: 14px;
  height: 14px;
  line-height: 10px;
  font-size: 9px;
  right: -3px;
  padding: 0.25em 0.4em;
}

.sak__side_extention_wrapper .whatsappOn .badge {
  background-color: #108043;
  /*#9C6ADE;*/
  top: -3px;
}

.sak__side_extention_wrapper .whatsappOn .badge2 {
  background-color: #399af2;
  bottom: 0px;
  top: inherit;
}

.sak__side_extention_wrapper .ext-metricas table {
  width: 100%;
  margin-bottom: 10px;
}

.sak__side_extention_wrapper .ext-metricas th {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #424242;
}

.sak__side_extention_wrapper .ext-metricas td {
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
}

.sak__side_extention_wrapper .printCelular {
  // right: 10px;
  // width: 380px;
  position: fixed;
  bottom: 0;
  right: 0;
  height: auto;
  width: 100%;
  background: #fff url('https://app.sak.com.br/image/whats_back.png') no-repeat;
  background-size: cover;
  border: 16px solid #ffffff;
  border-bottom: none;
  border-radius: 40px 40px 0 0;
  box-shadow: 0 1px 4px rgb(185, 210, 238);
  max-height: 90vh;
}

.sak__side_extention_wrapper .printCelular .contorno-cel {
  border-radius: 24px 24px 0 0;
  background: #075e54;
  height: 46px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sak__side_extention_wrapper .printCelular .contorno-cel span:first-child {
  width: 50px;
  height: 8px;
  background: #e4e8ef;
  border-radius: 4px;
  display: inline-flex;
}

.sak__side_extention_wrapper .printCelular .contorno-cel span:last-child {
  margin-left: 16px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #e4e8ef;
  display: inline-flex;
}

.sak__side_extention_wrapper .printCelular .header-cel {
  background: #128c7e;
  min-height: 60px;
  padding: 9px 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
}

.sak__side_extention_wrapper .printCelular .header-cel strong {
  margin-left: 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: white;
}

.sak__side_extention_wrapper .printCelular .msg-cel {
  margin-top: 20px;
  padding: 8px;
  margin-left: 50px;
  background: #dcf8c7;
  border-radius: 6px;
  margin-right: 15px;
  margin-bottom: 20px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: black;
  overflow-y: overlay !important;
  overflow-x: hidden;
  max-height: 90vh;
  white-space: break-spaces;
  word-break: break-word;
}

.sak__side_extention_wrapper .printCelular .msg-cel::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ccc;
}

.sak__side_extention_wrapper .printCelular .msg-cel::-webkit-scrollbar {
  width: 6px;
  background-color: #eee;
}

.sak__side_extention_wrapper .printCelular .msg-cel::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-top: 0 solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 10px solid #dcf8c7;
  position: absolute;
  top: 0;
  right: -9px;
}

.sak__side_extention_wrapper .printCelular .msg-cel img {
  border-radius: 6px;
  max-width: 180px;
  max-height: 180px;
  margin-bottom: 6px;
  display: block;
}


.sak__side_extention_wrapper .printCelular.left {
  right: unset;
  left: 70px;
}

.sak__side_extention_wrapper .order_section {
  background: var(--secondary);
  color: var(--basic-inverse);
  padding: .5rem;
  border-radius: 6px;
}


@media screen and (max-height: 720px) {
  #simMsg {
    max-height: 240px;
  }
}

@media screen and (max-height: 600px) {
  #simMsg {
    max-height: 160px;
  }
}

@media screen and (max-height: 560px) {
  #simMsg {
    opacity: .9;
  }
}



// SAD CSS - SAK


.sak__side_extention_wrapper .sak--perfil-info ul li,
.sak__side_extention_wrapper .sak--perfil-info ul li i {
  color: var(--light-medium) !important;
}

.sak__side_extention_wrapper .main-search .input-search::placeholder {
  color: var(--svg) !important;
}

.sak__side_extention_wrapper .sak--form-group label {
  color: var(--basic-inverse) !important;
}

.sak__side_extention_wrapper .sak--form-group input,
.sak__side_extention_wrapper .sak--form-group div,
.sak__side_extention_wrapper .sak--form-group textarea {
  border-color: var(--scrollbar) !important;
  color: var(--basic-inverse) !important;
}

.sak__side_extention_wrapper .sak--form-group input::placeholder {
  color: var(--light-medium) !important;
}

.sak__side_extention_wrapper .main-header {
  // padding-top: 8px !important;
  padding: 10px 10px 0 10px;
}

.sak__side_extention_wrapper .main-search .form-search {
  background: var(--secondary) !important;
  border-radius: 0.375rem !important;
  flex: 1 !important;
}

.sak__side_extention_wrapper .main-search .input-search {
  width: 100% !important;
  color: var(--basic-inverse) !important;
}

.sak__side_extention_wrapper .main-header .main-header-link i {
  color: var(--svg) !important;
  transition: all 0.3s;
}

.sak__side_extention_wrapper .main-header .main-header-link i:hover {
  color: var(--basic-inverse) !important;
}

.sak__side_extention_wrapper {
  padding: 10px !important;
  background: var(--light) !important;
}

.sak__side_extention_wrapper .info-nav-items .nav-tabs .nav-link {
  color: var(--svg);
}

.sak__side_extention_wrapper .lupa i {
  color: var(--svg) !important;
}

.sak__side_extention_wrapper .nav-item i {
  color: unset !important;
}

.sak__side_extention_wrapper .sak--ext-title i {
  color: var(--basic-inverse) !important;
}

.sak__side_extention_wrapper ul,
.sak__side_extention_wrapper strong,
.sak__side_extention_wrapper h5,
.sak__side_extention_wrapper span,
.sak__side_extention_wrapper h6,
.sak__side_extention_wrapper h4,
.sak__side_extention_wrapper {
  color: var(--basic-inverse) !important;
}

body .sak__side_extention_wrapper .chat-bg {
  background: var(--basic) !important;
  border-color: var(--scrollbar) !important;
}

.sak__side_extention_wrapper .chat-bg > div {
  color: var(--basic-inverse) !important;
}

.sak__side_extention_wrapper .btn-link {
  color: var(--basic-inverse) !important;
}

.sak__side_extention_wrapper .title-header2 {
  border-bottom: 1px solid var(--secondary) !important;
}

.sak__side_extention_wrapper .lista-artigos {
  background: var(--basic) !important;
}

.sak__side_extention_wrapper .faq--title:hover,
.sak__side_extention_wrapper .faq--title.active {
  background: var(--secondary) !important;
}

.sak__side_extention_wrapper .page-content-scroll::before {
  box-shadow: 0px 0px 25px 30px var(--light) !important;
}

.sak__side_extention_wrapper #sak-frame-tab {
  border-left: 1px solid var(--secondary);
}

.sak__side_extention_wrapper .page-content-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--scrollbar) !important;
}

.sak__side_extention_wrapper .title-header .text {
  color: var(--basic-inverse) !important;
}

.sak__side_extention_wrapper .title-header {
  border-bottom: 1px solid var(--secondary) !important;
}

.sak__side_extention_wrapper .form-text-item {
  background-color: var(--basic) !important;
  border-color: var(--basic) !important;
}

.sak__side_extention_wrapper .form-text-item input[type='text'],
.sak__side_extention_wrapper .form-text-item textArea {
  background: var(--basic);
  border: 1px solid var(--scrollbar);
  color: var(--basic-inverse);
}

.sak__side_extention_wrapper .main-header {
  background: transparent !important;
}

.sak__side_extention_wrapper .main-header a i {
  color: var(--basic-inverse) !important;
}

@media (max-width: 1200px) {
  .sak__side_extention_wrapper {
    padding: 0 15px !important;
  }
}


`;

export const Tabs = styled.li`
  position: relative;
  padding-top: 0.7rem;

  &:after {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff5848;
    width: 18px;
    height: 18px;
    z-index: 0;

    position: absolute;
    top: -5px;
    right: -8px;
    font-size: 0.7rem;

    border-radius: 12px;
    border: 1px solid var(--light);

    pointer-events: none;
    font-weight: bold;
    color: white;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    content: '${(props) => props.notif && props.notif}';
    display: ${(props) => (props.notif && props.notif > 0 ? 'flex' : 'none')};
  }

  a {
    padding: 0 0.45rem 0.7rem;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 0 !important;
    border: 0 !important;
    border-bottom: 2px solid transparent !important;
  }

  .active {
    border-radius: 0 !important;
    border: 0 !important;
    border-bottom: 2px solid var(--basic-inverse) !important;
  }

  @media (max-width: 900px) {
    a {
      padding: 0px 1.4rem 0.7rem;
      font-size: 1rem;
    }
  }

  @media (max-width: 562px) {
    a {
      padding: 0px 0.8rem 0.7rem;
    }
  }
`;
