import { sakBaseUrl } from './usefulFunctions';
import { preload } from 'swr';

export async function prefetchQuickReplies () {
  const queryParams = new URLSearchParams({
    tokenchat: window.sakChatToken,
    chave_empreender: window.sakChatChave,
  });

  const queryUrl = sakBaseUrl('/Js/GetQuickRepliesExtensaoV2?') + queryParams.toString();

  const res = await preload(queryUrl, async url => {
    const res = await fetch(url);
    const data = await res.json();
    return data;
  });

  if (Array.isArray(res?.data?.replies))
    window.SAKshortcut = Object.fromEntries(res.data.replies.map(replies => ([ replies.shortcut, replies.texto ])));
}
