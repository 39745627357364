import React, { useEffect, useState } from 'react';

// import {
//   useLojas,
//   useLojaAtiva,
//   useUserLogado,
//   useAtendente,
//   useContatos,
//   useMensagens,
//   useContatoAtivo,
//   useMarcadores,
//   useGlobalLoading,
//   // useLastMsg,
// } from './hooks/dadosSak';

import Tooltip from './contents/Tooltip';

import Avatar from './components/avatar';

import { BackgroundChat, LoadingChat } from './styles/GlobalStyles';

import { ErrorChat } from './components/errors';

import LoadApp from './LoadApp';

import RespostaProvider from './hooks/responderMsgContext';
import RespostasRapidas from './hooks/rapidaMsgContext';

import ListaLojas from './contents/ListaLojas';
import Aside from './contents/Aside';
import CaixaMensagem from './contents/CaixaMensagem';

import { restringirNome } from './utils/formatDados';
import { prefetchQuickReplies } from './utils/prefetch';
import useDadosSakStore from './stores/useDadosSakStore';
import useRenderStore from './stores/useRenderStore';
import CountrySelect from './components/CountrySelect';
import { useTranslation } from 'react-i18next';

export default function Home() {
  // const { mensagens } = useMensagens();
  // const { contatos } = useContatos();
  // const { userChat } = useAtendente();
  // const { lojas } = useLojas();
  // const { lojaAtivo } = useLojaAtiva();
  // const { userLogado } = useUserLogado();
  // const { contatoAtivo } = useContatoAtivo();
  // const { marcadores } = useMarcadores();
  // const { globalLoading } = useGlobalLoading();
  const [tab, setTab] = useState('Todos');
  const [search, setSearch] = useState('');

  const contatos = useDadosSakStore((state) => state.contatos);
  const contatoAtivo = useDadosSakStore((state) => state.contatoAtivo);
  const mensagens = useDadosSakStore((state) => state.mensagens);
  const userLogado = useDadosSakStore((state) => state.userLogado);
  const userChat = useDadosSakStore((state) => state.userChat);
  const lojas = useDadosSakStore((state) => state.lojas);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);
  const marcadores = useDadosSakStore((state) => state.marcadores);
  const globalLoading = useDadosSakStore((state) => state.globalLoading);
  const { t, i18n } = useTranslation();

  const lastRender = useRenderStore((state) => state.lastRender);

  // const { lastMsg } = useLastMsg();

  // useEffect(() => {
  //   if (globalLoading['percent'] === 99) {
  //     setTimeout(
  //       () =>
  //         setGlobalLoading({
  //           percent: 100,
  //           step: '',
  //         }),
  //       3000
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [globalLoading['percent']]);

  function copiarCodigo(texto) {
    if (!texto) return;
    texto = String(texto);
    if (typeof texto !== 'string') return;
    navigator.clipboard
      .writeText(texto)
      .then(() => window.notifChat('Código copiado com sucesso!', 'success'))
      .catch((_) =>
        window.notifChat('Não foi possível copiar o código!', 'warning')
      );
  }

  useEffect(() => {
    if (
      lojas &&
      Object.values(lojas).length &&
      lojaAtivo &&
      Object.values(lojaAtivo).length
    ) {
      window.sakChatToken =
        localStorage.inicializacao &&
        JSON.parse(localStorage.inicializacao).token;
      window.sakChatChave = lojas[lojaAtivo].chave_empreender;

      prefetchQuickReplies();
    }
  }, [lojaAtivo, lojas]);

  useEffect(() => {
    window.sakChatContato = contatoAtivo?.chatid;
    window.sakChatLoja = lojas?.[lojaAtivo]?.user;
    window.sakChatIsMeta = lojas?.[lojaAtivo]?.multidevice < 0;
  }, [lojas, lojaAtivo, contatoAtivo]);

  useEffect(() => {
    const allChats = document.querySelectorAll('#chat-tab');
    if (!allChats || !contatoAtivo || (contatoAtivo && !contatoAtivo.chatid))
      return;
    Object.values(allChats).forEach((chat) =>
      chat.dataset['ref'].includes(contatoAtivo.chatid)
        ? chat.classList.add('border-selected')
        : chat.classList.remove('border-selected')
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, search, contatoAtivo]);

  return (
    <>
      <ErrorChat codeError={8}>
        <LoadApp>
          <div className="layout">
            <div className="sidebar">
              <div style={{ height: '100dvh' }}>
                <div className="d-flex flex-column h-100">
                  <div className="container-fluid py-5">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center mt-2 text-truncate">
                        <a href="# " className="d-block">
                          <img
                            src={`${process.env.PUBLIC_URL}assets/logo.png`}
                            className="mx-auto fill-pink"
                            data-inject-svg=""
                            alt=""
                            style={{ height: 46 }}
                          />
                        </a>
                        <h4 className="font-bold ml-4 text-truncate">
                          {lojaAtivo && lojas[lojaAtivo]
                            ? lojas[lojaAtivo].nome
                            : 'Conversas'}
                        </h4>
                      </div>

                      <div
                        className="dropdown"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <CountrySelect />
                        <div
                          className="btn btn-sm text-muted w-auto p-0 pr-1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <Tooltip text="Perfil" position="bottom">
                            <i
                              className="fe fe-user"
                              style={{ fontSize: '1.3rem' }}
                            ></i>
                          </Tooltip>
                        </div>
                        <div className="dropdown-menu mr-5">
                          <a
                            className="dropdown-item d-flex align-items-center border-bottom mb-3"
                            href="# "
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="avatar">
                                <ErrorChat codeError={2}>
                                  {userLogado.userchat &&
                                  userChat[userLogado.userchat] ? (
                                    <Avatar
                                      dados={userChat[userLogado.userchat]}
                                    />
                                  ) : null}
                                </ErrorChat>
                              </div>
                              <div
                                className="ml-4 d-flex flex-column justify-content-center"
                                style={{ lineHeight: '19px' }}
                              >
                                <strong>
                                  {userLogado.userchat &&
                                    userChat[userLogado.userchat] &&
                                    restringirNome(
                                      userChat[userLogado.userchat].nome,
                                      15
                                    )}
                                </strong>
                                {userLogado.userchat &&
                                userChat[userLogado.userchat]
                                  ? userChat[userLogado.userchat].email
                                  : null}
                              </div>
                            </div>
                          </a>
                          <a
                            className="dropdown-item d-flex flex-column"
                            href="# "
                            onClick={() =>
                              copiarCodigo(
                                lojaAtivo !== 0 &&
                                  lojas[lojaAtivo] &&
                                  lojas[lojaAtivo].chave_empreender
                              )
                            }
                          >
                            <strong style={{ color: 'var(--azul)' }}>
                              {t('Chave Empreender')}
                            </strong>
                            <div>
                              <span className="ml-auto mr-2 fe-copy" />
                              {lojaAtivo !== 0 &&
                                lojas[lojaAtivo] &&
                                lojas[lojaAtivo].chave_empreender}
                            </div>
                          </a>
                          <a
                            className="dropdown-item d-flex align-items-center border-top mt-2"
                            href="https://app.sak.com.br/atualizarUsuario"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t('Minha Conta')}{' '}
                            <span className="ml-auto fe-user" />
                          </a>
                          <a
                            id="darkmode"
                            className="dropdown-item d-flex align-items-center border-top mt-2"
                            href="# "
                          >
                            <span className="mr-2">{t('Ativar')} </span>{' '}
                            {t('Modo Noturno')}
                            <span className="ml-3 fe-moon" />
                          </a>
                          <a
                            className="dropdown-item d-flex align-items-center"
                            href="https://app.sak.com.br/dashboard"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t('Voltar para o SAK')}{' '}
                            <span className="ml-auto fe-log-out" />
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* Title */}
                  </div>
                  <ErrorChat codeError={3}>
                    {lojaAtivo && (
                      <Aside
                        contatos={contatos[lojaAtivo]}
                        mensagens={mensagens[lojaAtivo]}
                        lojaAtivo={lojaAtivo}
                        marcadores={marcadores[lojaAtivo]}
                        lojas={lojas[lojaAtivo]}
                        search={search}
                        setSearch={setSearch}
                        tab={tab}
                        setTab={setTab}
                        lastRender={lastRender}
                      />
                    )}
                  </ErrorChat>
                  <div className="border-top">
                    <div className="container-fluid p-0">
                      <ErrorChat codeError={4}>
                        <ListaLojas />
                      </ErrorChat>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="main" id="main-chat" data-mobile-height>
              {contatoAtivo && Object.values(contatoAtivo).length ? (
                <RespostaProvider>
                  <RespostasRapidas>
                    <CaixaMensagem
                      // lastMessage={lastMsg ? lastMsg[lastMsg.length - 1] : ''}
                      contato={contatos[lojaAtivo]}
                      userchat={userChat}
                      mensagens={mensagens[lojaAtivo]}
                      inicial={userLogado}
                      lojas={lojas[lojaAtivo]}
                      lojaAtivo={lojaAtivo}
                      marcadores={marcadores[lojaAtivo]}
                    />
                  </RespostasRapidas>
                </RespostaProvider>
              ) : (
                <ErrorChat codeError={7}>
                  {globalLoading['percent'] !== 100 ? (
                    <BackgroundChat>
                      <img
                        src={`${process.env.PUBLIC_URL}assets/backgroundChat.svg`}
                        alt="backgroundLoading"
                      />
                      <h5>
                        {t('Carregando')} {globalLoading['step']}
                      </h5>
                      <h6>{t('Este processo pode demorar alguns minutos.')}</h6>
                      <LoadingChat percent={globalLoading['percent']} />
                    </BackgroundChat>
                  ) : (
                    <BackgroundChat
                      display={contatoAtivo && contatoAtivo.chatid}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}assets/backgroundChat.svg`}
                        alt="backgroundChat"
                      />
                      <span>
                        {userLogado.userchat && userChat[userLogado.userchat]
                          ? `${t('Olá')}, ` +
                            userChat[userLogado.userchat].nome +
                            `, ${t('seja bem vindo(a)!')}`
                          : `${t('Carregando...')}`}
                      </span>
                      <p>
                        {t(
                          'Selecione uma conversa ao lado para iniciar um diálogo!'
                        )}
                      </p>
                    </BackgroundChat>
                  )}
                </ErrorChat>
              )}
            </div>
          </div>
        </LoadApp>
      </ErrorChat>
    </>
  );
}
